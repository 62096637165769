import React, { Fragment, useState, useEffect } from "react";
import testimonials from "../data/testimonials.json";

const TestimonialsSection = () => {
  // index here means the index of review being shown on screen for eg. 1st review 2nd review and so on
  const [currentIndex, setCurrentIndex] = useState(0);

  // Automatically change testimonials every 3 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000);

    return () => clearInterval(interval); // Clear interval on component unmount
  }, []);

  // Handle dot click to go to review asked by viewer for eg if someone wants to go back to previous review  in order to watch it
  const goToTestimonial = (index) => {
    setCurrentIndex(index);
  };

  return (
    <Fragment>
      {/* Testimonial Section */}
      <section className="testimonial-section" id="testimonials">
        <div className="testimonial-container">
          {/* Image of Reviewer */}

          <div className="testimonial-image">
            <img
              src={
                /*If image exists show that if it does not show a default image */
                testimonials[currentIndex].image
                  ? `/images/testimonials/${testimonials[currentIndex].image}`
                  : "/images/testimonials/default.jpg"
              }
              alt={testimonials[currentIndex].name}
            />
          </div>
          {/* Content Section */}
          <div className="testimonial-content">
            <p className="testimonial">{testimonials[currentIndex].feedback}</p>
            <p className="author">{testimonials[currentIndex].name}</p>
            <p className="designation">
              {testimonials[currentIndex].designation},{" "}
              {testimonials[currentIndex].organization}
            </p>
          </div>
        </div>

        {/* Carousel Dots */}
        <div className="carousel-dots">
          {testimonials.map((_, index) => (
            <button
              key={index}
              className={index === currentIndex ? "active" : ""}
              onClick={() => goToTestimonial(index)}
            ></button>
          ))}
        </div>
      </section>
    </Fragment>
  );
};

export default TestimonialsSection;
