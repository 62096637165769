// importing necessary components from packages
import React, { Fragment } from "react";

// importing self built components for homepage
import Header from "../Components/Header";
import OurStory from "../Components/OurStory";
import Services from "../Components/Services";
import HeroSection from "../Components/HeroSection";
import ContactForm from "../Components/ContactForm";
import TestimonialsSection from "../Components/Testimonials";

const Home = () => {
  return (
    <Fragment>
      <Header />
      <HeroSection />
      <OurStory />
      <Services />
      <TestimonialsSection />
      <ContactForm />
    </Fragment>
  );
};

export default Home;
