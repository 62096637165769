import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import LoginHeader from "./LoginHeader";
const NotFound = () => {
  return (
    <Fragment>
      <LoginHeader />
      <div className="not-found-container">
        <h1 className="not-found-title">404</h1>
        <p className="not-found-message">Page Not Found</p>
        <p className="not-found-description">
          Sorry, the page you are looking for does not exist or has been moved.
        </p>
        <Link to="/" className="not-found-button">
          Go Back Home
        </Link>
      </div>
    </Fragment>
  );
};

export default NotFound;
