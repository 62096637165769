import React from "react";
import backgroundImage from "../assets/truck.webp";

const HeroSection = () => {
  return (
    <div
      className="hero-section"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      {/*To add a bit darkness over the background Image */}
      <div className="hero-overlay">
        {/*Tag Line on Homepage */}
        <h1 className="hero-title">
          Driving Efficiency, Delivering Intelligence
        </h1>
      </div>
    </div>
  );
};

export default HeroSection;
