import React, { useState } from "react";
import services from "../data/services.json";

const Services = () => {
  // To handle opening and closing of modal (kind of pop up box which is added on website)

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({
    title: "",
    description: "",
  });

  // To scroll upto required component

  // To open Modal
  const openModal = (service) => {
    setModalContent({
      title: service.title,
      //using detailed in description as after opening modal we want to show detailed version instead of brief description
      description: service.detailed,
    });
    setIsModalOpen(true);
  };

  // To close modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <section className="services-section" id="services">
      <div>
        {/* Title */}
        <h2>Our Services</h2>
        <p>Tailored solutions to enhance your logistics operations</p>

        {/* Service Cards */}
        <div className="services-grid-container">
          <div className="services-grid">
            {services.map((service, index) => (
              <div
                key={index}
                className="service-card"
                onClick={() => openModal(service)}
              >
                <div>
                  <h3>{service.title}</h3>
                  <span>&gt;</span>
                </div>
                <p>{service.description}</p>
                <p className="read-more">Click here to read more</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="close-button" onClick={closeModal}>
              &times;
            </button>
            <h3>{modalContent.title}</h3>
            <p>{modalContent.description}</p>
          </div>
        </div>
      )}
    </section>
  );
};

export default Services;
