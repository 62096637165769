import React from "react";
import { FaFacebook, FaLinkedinIn, FaTwitter } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-text">
        <p>© All Rights Reserved. Veyza</p>
      </div>
      <div className="footer-icons">
        {/*Redirect to Facebook.com or our facebook page */}
        <a
          href="https://facebook.com"
          target="_blank"
          rel="noopener noreferrer"
          className="footer-icon-link"
        >
          <FaFacebook />
        </a>

        {/*Redirect to twitter.com or our twitter page */}
        <a
          href="https://twitter.com"
          target="_blank"
          rel="noopener noreferrer"
          className="footer-icon-link"
        >
          <FaTwitter />
        </a>

        {/*Redirect to linkedin.com or our linkedin page */}
        <a
          href="https://www.linkedin.com/company/veyza-technologies-pvt-ltd/posts/"
          target="_blank"
          rel="noopener noreferrer"
          className="footer-icon-link"
        >
          <FaLinkedinIn />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
